import React, { useEffect, useState } from "react";
import "./style.scss";
import Column from "../../components/tableColumn";
import { GetTariffs } from "../../api";
import { useTranslation } from "react-i18next";
import { Fade } from "react-awesome-reveal";

const Tariffs = () => {
  const [{ standart, prime, primePlus }, setTariffs] = useState({
    standart: {},
    prime: {},
    primePlus: {},
  });
  const [hasData, setHasData] = useState(false);
  useEffect(() => {
    GetTariffs().then(({ data }) => {
      if (data && data.length) {
        setHasData(true);
        setTariffs({
          standart: data[0],
          prime: data[1],
          primePlus: data[2],
        });
      }
    });
  }, []);
  const [tariff, setTariff] = useState("standart");

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const { t, i18n } = useTranslation();

  const getClassNameDisplayNone = (tariff, type) =>
    windowWidth <= 1150
      ? tariff === type
        ? "tariffInfo"
        : "displayNone"
      : null;

  const columnsData = [
    {
      title: t("Minimum balance (AMD)"),
      standart: standart.minBalance,
      prime: prime.minBalance,
      primePluse: primePlus.minBalance,
    },
    {
      title: "Armenian exchange",
      classNameTitle: "lightTitle",
    },
    {
      title: "Government and corporate bonds",
      standart: standart.govBonds,
      prime: prime.govBonds,
      primePluse: primePlus.govBonds,
    },
    {
      title: "Global markets",
      background: "darkLine",
      classNameTitle: "lightTitle",
    },
    {
      title: "Exchange traded stocks/bonds",
      background: "darkLine",
      standart: standart.exBonds,
      secondLineStandart: t("(min 1$)"),
      prime: prime.exBonds,
      primePluse: primePlus.exBonds,
    },
    {
      title: "FX",
      classNameTitle: "lightTitle",
    },
    {
      title: "G-10 Currencies",
      standart: standart.g10Currencies,
      prime: prime.g10Currencies,
      primePluse: primePlus.g10Currencies,
    },
    {
      title: "Reginal currencies",
      standart: standart.regCurrencies,
      prime: prime.regCurrencies,
      primePluse: primePlus.regCurrencies,
    },
    {
      title: "OTC",
      background: "darkLine",
      classNameTitle: "lightTitle",
    },
    {
      title: "Eurobonds",
      background: "darkLine",
      standart: standart.otc_euroBonds,
      prime: prime.otc_euroBonds,
      primePluse: primePlus.otc_euroBonds,
    },
    {
      title: "ADRs",
      background: "darkLine",
      standart: standart.otc_ADRs,
      prime: prime.otc_ADRs,
      primePluse: primePlus.otc_ADRs,
    },
    {
      title: "GDRs",
      background: "darkLine",
      standart: standart.otc_GDRs,
      prime: prime.otc_GDRs,
      primePluse: primePlus.otc_GDRs,
    },
    {
      title: "Custody annual fee",
      classNameTitle: "lightTitle",
    },
    {
      title: "Eurobonds",
      standart: standart.caf_euroBonds,
      prime: prime.caf_euroBonds,
      primePluse: primePlus.caf_euroBonds,
    },
    {
      title: "ADRs",
      standart: standart.caf_ADRs,
      prime: prime.caf_ADRs,
      primePluse: primePlus.caf_ADRs,
    },
    {
      title: "GDRs",
      standart: standart.caf_GDRs,
      prime: prime.caf_GDRs,
      primePluse: primePlus.caf_GDRs,
    },
    {
      title: "local bonds",
      standart: standart.localBonds,
      prime: prime.localBonds,
      primePluse: primePlus.localBonds,
    },
    {
      title: "local stocks",
      standart: standart.localStocks,
      prime: prime.localStocks,
      primePluse: primePlus.localStocks,
    },
    {
      title: "global bonds",
      standart: standart.globalBonds,
      prime: prime.globalBonds,
      primePluse: primePlus.globalBonds,
    },
    {
      title: "global stocks",
      standart: standart.globalStocks,
      prime: prime.globalStocks,
      primePluse: primePlus.globalStocks,
    },
    {
      title: "Free of Payment Transfers within CDA",
      standart: standart.freePaymentWithinCDA,
      prime: prime.freePaymentWithinCDA,
      primePluse: primePlus.freePaymentWithinCDA,
    },
    {
      title: "Free of Payment Transfers outside CDA",
      standart: standart.freePaymentOutsideCDA,
      prime: prime.freePaymentOutsideCDA,
      primePluse: primePlus.freePaymentOutsideCDA,
    },
    {
      title: "Asset Management",
      background: "darkLine",
      classNameTitle: "lightTitle",
    },
    {
      title: "Management fee",
      background: "darkLine",
    },
    {
      title: "Individual strategy",
      background: "darkLine",
      standart: standart.individualStrategy,
      prime: prime.individualStrategy,
      primePluse: primePlus.individualStrategy,
    },
    {
      title: "Index strategies – ETFs",
      background: "darkLine",
      standart: standart.indexStrategies,
      prime: prime.indexStrategies,
      primePluse: primePlus.indexStrategies,
    },
    {
      title: "Success fee",
      background: "darkLine",
    },
    {
      title: "Individual strategy",
      background: "darkLine",
      standart: standart.individualStrategySuccess,
      prime: prime.individualStrategySuccess,
      primePluse: primePlus.individualStrategySuccess,
    },
    {
      title: "Index strategies – ETFs",
      background: "darkLine",
      standart: standart.indexStrategiesSuccess,
      prime: prime.indexStrategiesSuccess,
      primePluse: primePlus.indexStrategiesSuccess,
    },
    {
      title: "Advisory",
      classNameTitle: "lightTitle",
    },
    {
      title: "Personal advisor",
      standart: standart.personalAdvisor,
      prime: prime.personalAdvisor,
      primePluse: primePlus.personalAdvisor,
    },
    {
      title: "Robo Advisor",
      standart: standart.roboAdvisor,
      prime: prime.roboAdvisor,
      primePluse: primePlus.roboAdvisor,
    },
  ];

  return (
    <div className="tariffs">
      <Fade delay={600} direction="down" triggerOnce>
        <h1>{t("tariffs")}</h1>
      </Fade>

      <div className="tariffsSwitch">
        <input name="tariff" type="radio" id="standard" defaultChecked />
        <label
          onClick={() => {
            setTariff("standart");
          }}
          htmlFor="standard"
          className="label1"
        >
          {t("standart")}
        </label>

        <input name="tariff" type="radio" id="prime" />
        <label
          onClick={() => {
            setTariff("prime");
          }}
          htmlFor="prime"
          className="label2"
        >
          {t("prime")}
        </label>

        <input name="tariff" type="radio" id="primePluse" />
        <label
          onClick={() => {
            setTariff("primePluse");
          }}
          htmlFor="primePluse"
          className="label3"
        >
          {t("prime")} +
        </label>
      </div>

      {hasData ? (
        <table>
          <thead>
            <tr>
              <th></th>
              <th>{t("standart")}</th>
              <th>{t("prime")}</th>
              <th>{t("prime")} +</th>
            </tr>
          </thead>
          <tbody>
            {columnsData.map((column, index) => (
              <Column
                key={index}
                index={index}
                background={column.background}
                classNameTitle={column.classNameTitle}
                title={t(column.title)}
                standart={"standart" in column ? String(column.standart) : ""}
                prime={"prime" in column ? String(column.prime) : ""}
                primePluse={
                  "primePluse" in column ? String(column.primePluse) : ""
                }
                classNameDisplayNone1={getClassNameDisplayNone(
                  tariff,
                  "standart"
                )}
                classNameDisplayNone2={getClassNameDisplayNone(tariff, "prime")}
                classNameDisplayNone3={getClassNameDisplayNone(
                  tariff,
                  "primePluse"
                )}
                secondLineStandart={column.secondLineStandart}
              />
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tfoot>
        </table>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Tariffs;
